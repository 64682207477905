import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Core } from '@npmjs_techiia/backoffice-tril'

import bgMain from './assets/bgMain.svg'
import logoWhite from './assets/logo.svg'
import loginImg from './assets/cover.jpg'
import logoDark from './assets/logo-black.svg'

const mainColor = '#8fa7c8'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StrictMode>
    <Core
      bgMainDark={bgMain}
      loginImg={loginImg}
      logoDark={logoDark}
      logoWhite={logoWhite}
      mainColor={mainColor}
    />
  </StrictMode>,
)
